import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/wilmer/projects/wilmercantillo.com/src/components/md/index.tsx";
import { graphql } from 'gatsby';
import { Badge, Link, Text } from '@chakra-ui/layout';
import { FaExternalLinkAlt } from 'react-icons/fa';
export const ProjectQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    banner: file(
      relativePath: { eq: "project/caja-naranja/images/banner.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  ProjectQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Objetivo`}</h1>
    <p>{`El objetivo de este proyecto fue realizar una aplicación web para ofrecer el
servicio de almacenamiento en bodegas. La aplicación cuenta con las siguientes
funcionalidades: pago en línea, notificaciones por correo, autenticación y
autorización.`}</p>
    <h1>{`Sobre el Proyecto`}</h1>
    <p>{`Este proyecto lo desarrolle en conjunto con un amigo, como una idea de negocio y
para explorar el proceso de planeación y construcción de un emprendimiento
digital.`}</p>
    <h1>{`Construcción del proyecto`}</h1>
    <p>{`En la fase inicial del proyecto, definimos los requerimientos para un MVP y
realizamos el modelo de la `}<b>{`base de datos`}</b>{` (MySQL) la cual seria montada en
un RDS en AWS.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "693px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/858dd53dcc5fb454e4ab640a6ed51b54/61c63/db-model.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAACX0lEQVQoz32SyW7bMBCG9f4P0WOPDVoglzYNkDiGl3hRrJXWZu1WKEu2aC0ktXTsIIegTQcDCPwxH4fzj4SbJ+l2ro+2+F6NRlrA265m7e1UuxlLDyi5V+M7ySuqZhiGiez+GIljFE9M/KCG+4wIS0meLkXFcF6PJCNV23a8Y5KuzxZr1bBxXpzONeMcYNVyxktppVl2nAVZmZNSSA+HfRQ5tlXXNVT0HcAdTtN9FNqWWZ7PIHZd1w99mmcg+u4uS1MQGaOC47oGQluEojA4HfOh71nX7Xzftm2EUJIk3TWg2g4CWZbX67VlWXCklAoLSZ+/qJPVZrx4mYsKh+h723UVKJQlz3MZpZcXDUOQJJqmKYrieV7btowxoaac8jY/FQ3vKL90qClFprWRFHEjy5quIYNeeRj/kKYpxsc8L4riAg+Xa/umaa7fy4kytosSO0g00zXdyHACSmEUmCXYmra+NZBhKhrC+HCFAWjq4T3abjiVFMwMX1PS8ALMBmnok5z4r/lbuvvDuWqE0Yt5N9vcr43V7rCw9g1lOam+/px++f7725P0iJJfkg/Y9V398DGE0Up5XGkbO7ZxqYeHiwW0juIQY9zUdX/1mV/33H/MC2wZCCdxcczK4tS1vOWcVJXpOKqq6rpeAf8O/x2CqiNN12VFqaoaXCGE5IQgy4JViaIYBAGIn8JhHIdQ4ntVBb8Tgc6sbfcY+57n+z7n7fCfzpOlOF2sx/Pl5Hn1NJ1D54Yx2K6myoosOZbp7hxSfGJYVlTpqXxLfDxDxYmUzzKavWiQU1F9mK3TLP8n/Ae5azz7AcMeYgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Database model",
            "title": "Database model",
            "src": "/static/858dd53dcc5fb454e4ab640a6ed51b54/61c63/db-model.png",
            "srcSet": ["/static/858dd53dcc5fb454e4ab640a6ed51b54/5a46d/db-model.png 300w", "/static/858dd53dcc5fb454e4ab640a6ed51b54/0a47e/db-model.png 600w", "/static/858dd53dcc5fb454e4ab640a6ed51b54/61c63/db-model.png 693w"],
            "sizes": "(max-width: 693px) 100vw, 693px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Luego realice el `}<strong parentName="p">{`diseño`}</strong>{` de una interfaz de usuario para el landingpage, el
login/registro y una pagina para observar una lista de bodegas, en la cual luego
se podría buscar y filtrar resultados.`}</p>
    <Link target="_blank" href="https://www.figma.com/file/1SAUghYzdS9fmC4LVDnCjh/caja-Naranja?node-id=0%3A1" mdxType="Link">
  <Badge variant="subtle" colorScheme="black" textTransform="capitalize" float="right" display="flex" alignItems="center" mdxType="Badge">
    <Text marginRight="0.4rem" mdxType="Text">Diseño</Text>
    <FaExternalLinkAlt mdxType="FaExternalLinkAlt" />
  </Badge>
    </Link>
    <p><img parentName="p" {...{
        "src": "./images/search-warehouse.png",
        "alt": "Search Warehouse Page"
      }}></img></p>
    <p>{`Para el `}<strong parentName="p">{`backend`}</strong>{` de la aplicación usamos express para construir una API REST,
usando el patron MVC, la cual contaba las siguientes características:`}</p>
    <ul>
      <li parentName="ul">{`Endpoints para traer la data de las bodegas de manera paginada y filtrada.`}</li>
      <li parentName="ul">{`Servicio de autenticación y autorización con JWT usando la librería passport.`}</li>
      <li parentName="ul">{`Servicio de pago usando la pasarela de pago de mercadopago.`}</li>
      <li parentName="ul">{`Servicio de notificaciones con correo usando la librería nodemailer.`}</li>
    </ul>
    <p>{`En cuanto al `}<strong parentName="p">{`frontend`}</strong>{` realizamos una SPA con las siguientes características:`}</p>
    <ul>
      <li parentName="ul">{`Usamos el patrón 7-1 de Sass para manejar los estilos de la aplicación.`}</li>
      <li parentName="ul">{`Paginas de inicio de sesión y registro.`}</li>
      <li parentName="ul">{`Pagina para explorar bodegas, esta pagina ofrece información sobre el estado
de las bodegas en tiempo real.`}</li>
    </ul>
    <h1>{`Conclusión`}</h1>
    <p>{`A pesar de no haber podido continuar con la ejecución del proyecto debido a la
situación de la pandemia global, este proyecto fue otra experiencia importante
para desarrollar habilidades que luego iba a necesitar cuando estuviera
ejerciendo en el campo profesional. Además la gran cantidad de trabajo y
esfuerzo que requiere llevar una idea de un producto a la realidad.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      